
const digest = '12833c4ee73cc78dcf12ad48976689901984075ed6f463447d13a81054e9c372';
const css = `._wrapper_cgpew_1 {
  padding: 0.5rem 1.5rem;
}

._value_cgpew_5 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1;
  color: var(--color-neutral-10);
}

._label_cgpew_13 {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1;
  color: var(--color--neutral-10);
  padding-left: 0.125rem;
}

/* Variants */
._highlighted_cgpew_22 ._value_cgpew_5 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 1;
  color: var(--color-neutral-40);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"wrapper":"_wrapper_cgpew_1","value":"_value_cgpew_5","label":"_label_cgpew_13","highlighted":"_highlighted_cgpew_22"};
export { css, digest };
  