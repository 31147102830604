
const digest = 'fe6b7f348fb28da3f8b82e32b64d9a8cc4c2d216c45abfe96ecba93830b6ecf0';
const css = `._card_rrs2e_1 {
  background: #fff;
  border: 1px solid #e7e7ed;
}

._withPadding_rrs2e_6 {
  padding: 1rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"card":"_card_rrs2e_1","withPadding":"_withPadding_rrs2e_6"};
export { css, digest };
  