import { Link } from "../Link";

import { FormattedTextRule } from "./FormattedText";

export const urlRule: FormattedTextRule = {
  matcher: /^https?:\/\/\S+/,
  render: ({ text: link }: { text: string }) => (
    <Link href={link} target="_blank">
      {link}
    </Link>
  ),
};

export const emailRule: FormattedTextRule = {
  matcher:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  render: ({ text: link }: { text: string }) => (
    <Link href={`mailto:${link}`}>{link}</Link>
  ),
};
