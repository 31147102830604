
const digest = 'bbf67f28ea3466283a7c78f544dc5ced8e6d952e23d817ac325ee1e82a3b7fba';
const css = `._fieldGroupLabel_1y59t_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-neutral-10);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"fieldGroupLabel":"_fieldGroupLabel_1y59t_1"};
export { css, digest };
  